var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "LongDistanceFormComponent",
      attrs: { "data-test-id": "long_distance-form" },
    },
    [
      !_vm.isRemoveModalVisible
        ? _c(
            "GenericModalComponent",
            _vm._b(
              { attrs: { title: _vm.modalTitle } },
              "GenericModalComponent",
              _vm.$attrs,
              false
            ),
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "MuiValidationWrapper",
                    {
                      staticClass: "row",
                      on: {
                        areAllValid: (isValid) => (_vm.isFormValid = isValid),
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-12 mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            staticClass: "w-100",
                            attrs: {
                              index: _vm.VEHICLES_INDEX,
                              filters: `${_vm.getOperatorFilter({
                                index: _vm.VEHICLES_INDEX,
                              })} AND non_connected:0`,
                              disabled: !_vm.isNull(_vm.longDistance),
                              title: (res) =>
                                `${res.license_plate} - ${res.vehicle_brand} ${res.vehicle_model}`,
                              label: "Vehicle*",
                              name: "vehicle_uuid",
                              "path-value": "uuid",
                              placeholder: "Select vehicle",
                              "data-test-id": "vehicle-select",
                            },
                            model: {
                              value: _vm.inputs.vehicle_uuid,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "vehicle_uuid", $$v)
                              },
                              expression: "inputs.vehicle_uuid",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-6 mb-4 mb-sm-0" },
                        [
                          _c("MuiDatePicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isValidDate:
                                    _vm.DATE_INPUT_VALIDATIONS.validDate,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              date: _vm.start,
                              disabled: _vm.isEntityStarted,
                              "data-test-id": "start_date-select",
                              "skip-time-step": "",
                              label: "Start date*",
                            },
                            on: { "update:date": _vm.setStart },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-6" },
                        [
                          _c("MuiDatePicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isValidDate:
                                    _vm.DATE_INPUT_VALIDATIONS.validDate,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              date: _vm.end,
                              disabled: _vm.isEntityFinished,
                              "data-test-id": "end_date-select",
                              "skip-time-step": "",
                              label: "End date*",
                            },
                            on: { "update:date": _vm.setEnd },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-fill align-content-center justify-content-between",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _vm.longDistance && !_vm.isEntityStarted
                          ? _c("RemoveButton", {
                              attrs: {
                                disabled: _vm.longDistanceDetailStatus.LOADING,
                                "data-test-id": "remove-button",
                              },
                              on: {
                                click: () => (_vm.isRemoveModalVisible = true),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center justify-content-sm-end align-items-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c("CancelButton", {
                              attrs: { "data-test-id": "cancel-button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("closeModal")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c(
                              "ui-button",
                              {
                                staticClass: "wmin-initial",
                                attrs: {
                                  disabled: !_vm.areAllRequiredFieldsFilled,
                                  loading: _vm.longDistanceDetailStatus.LOADING,
                                  "data-test-id": "edit_create-button",
                                },
                                on: {
                                  clickbutton: function ($event) {
                                    _vm.longDistance
                                      ? _vm.editLongDistance()
                                      : _vm.createLongDistance()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.longDistance ? "Save" : "Create"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm.isRemoveModalVisible
        ? _c("RemoveForm", {
            attrs: {
              header: null,
              "long-distance": _vm.longDistance,
              callback: _vm.onSuccessRemoveLongDistance,
              "data-test-id": "remove-form",
            },
            on: { closeModal: () => (_vm.isRemoveModalVisible = false) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "AvailabilityView d-flex flex-column flex-fill",
      attrs: { "data-test-id": "availability-view" },
    },
    [
      _c(
        "PageView",
        { staticClass: "emobg-background-color-white" },
        [
          _c("MuiTabs", {
            staticClass: "emobg-background-color-white",
            attrs: {
              id: "AvailabilityTabs",
              tabs: _vm.tabs,
              active: _vm.activeTabIndex,
            },
            on: { "active-tab": _vm.activateTab },
          }),
        ],
        1
      ),
      _c(
        "PageView",
        {
          staticClass: "d-flex flex-column flex-fill AvailabilityView__content",
          attrs: { "is-tab-content": "" },
        },
        [
          _c("div", { staticClass: "d-flex d-md-none" }, [
            _c(
              "div",
              { staticClass: "col col-10" },
              [
                _c("ui-select", {
                  staticClass: "w-100 mb-2",
                  attrs: {
                    value: _vm.get(_vm.tabs[_vm.activeTabIndex], "url", ""),
                    required: "",
                    name: "items",
                    "option-value": "url",
                    "data-test-id": "select",
                  },
                  domProps: { options: _vm.tabsSelection },
                  on: { selectoption: ({ detail }) => _vm.activateTab(detail) },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col col-2 mb-3 d-flex align-items-center justify-content-center",
              },
              [
                _c(
                  "ui-dropdown",
                  { key: _vm.availabilityActionLabel },
                  [
                    _c(
                      "ui-button",
                      {
                        attrs: {
                          slot: "trigger",
                          color: _vm.GRAYSCALE.white,
                          contrast: "",
                          square: "",
                        },
                        slot: "trigger",
                      },
                      [
                        _c("ui-icon", {
                          attrs: {
                            color: _vm.GRAYSCALE.inkLight,
                            icon: _vm.ICONS.optionsHorizontalFull,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("ui-dropdown-actions", {
                      attrs: { slot: "content", "data-test-id": "list" },
                      domProps: { actions: _vm.availabilityActions },
                      slot: "content",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "d-md-flex justify-content-between d-none mb-2" },
            [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("h2", [
                    _vm._v(" " + _vm._s(_vm.get(_vm.activeTab, "label")) + " "),
                  ]),
                  _c(
                    "ui-tooltip",
                    {
                      staticClass: "ml-1",
                      attrs: {
                        "no-arrow": "",
                        tooltip: _vm.get(_vm.activeTab, "tooltip"),
                        "data-test-id": "tooltip",
                      },
                    },
                    [
                      _c("ui-icon", {
                        staticClass:
                          "emobg-color-ink-light emobg-color-ink-hover",
                        attrs: {
                          icon: _vm.ICONS.infoFull,
                          size: _vm.ICONS_SIZES.small,
                          "data-test-id": "icon",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.get(_vm.activeTab, "action")
                ? _c(
                    "ui-button",
                    {
                      attrs: { "data-test-id": "create-button" },
                      on: {
                        clickbutton: () => _vm.invoke(_vm.activeTab, "action"),
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.availabilityActionLabel) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("RouterView"),
        ],
        1
      ),
      _vm.availabilityForm && _vm.isAvailabilityFormVisible
        ? _c(_vm.availabilityForm, {
            tag: "Component",
            attrs: {
              callback: _vm.get(_vm.activeTab, "callback"),
              "close-modal": _vm.closeModal,
              title: _vm.availabilityActionLabel,
              "data-test-id": "form",
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "AvailabilityMobileFilters row",
      attrs: { "data-test-id": "availability_mobile-filters" },
    },
    [
      _c(
        "div",
        { staticClass: "col-12 mb-3" },
        [
          _vm.filters.cities
            ? _c("ui-select-multiple", {
                staticClass: "w-100 mb-2",
                attrs: {
                  placeholder: _vm.upperFirst(_vm.$t("Common.Business.cities")),
                  name: "cities",
                  "data-test-id": "cities-select",
                },
                domProps: {
                  values: _vm.filters.cities,
                  options:
                    _vm.citiesToRequest &&
                    _vm.citiesToRequest.map(({ uuid, name }) => ({
                      value: uuid,
                      label: name,
                    })),
                },
                on: {
                  selectoptions: ({ detail }) => (_vm.filters.cities = detail),
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("MonthSelectorComponent", {
            attrs: { "data-test-id": "month-select" },
            model: {
              value: _vm.filters.date,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "date", $$v)
              },
              expression: "filters.date",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        _vm._l(_vm.viewTypes, function (viewType, index) {
          return _c(
            "div",
            { key: viewType.label },
            [
              _c("ui-radio", {
                staticClass: "mb-3 mt-2 w-100",
                attrs: {
                  value: _vm.filters.selectedView,
                  caption: viewType.label,
                  option: `${index}`,
                  name: "viewType",
                  "data-test-id": `type-${_vm.kebabCase(
                    viewType.label
                  )}-button`,
                },
                on: {
                  changevalue: () => {
                    _vm.filters.selectedView = index
                    _vm.setViewType(index)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "LongDistanceEventComponent text-ellipsis",
      attrs: { "data-test-id": "long_distance-event" },
    },
    [
      _c(
        "div",
        {
          staticClass: "text-ellipsis",
          attrs: { title: _vm.tooltipText, "data-test-id": "tooltip" },
        },
        [
          _c("b", { staticClass: "emobg-font-weight-bold" }, [
            _vm._v(" Long Distance "),
          ]),
          _vm._v(
            " ( " +
              _vm._s(
                _vm.moment(_vm.availability.start).format(_vm.DATE_FORMAT.dob)
              ) +
              " to " +
              _vm._s(
                _vm.moment(_vm.availability.end).format(_vm.DATE_FORMAT.dob)
              ) +
              " ) "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "VehicleAllocationView full-height d-flex flex-column flex-fill",
      attrs: { "data-test-id": "vehicle_allocation-view" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "VehicleAllocationView__mobileSubHeader row no-gutters px-2 d-flex d-md-none",
          attrs: { "data-test-id": "mobile_sub_header" },
        },
        [
          _c(
            "div",
            { staticClass: "col col-10" },
            [
              _c("MuiInputText", {
                staticClass: "w-100 mb-2",
                attrs: {
                  placeholder: _vm.upperFirst(_vm.$t("Common.Actions.search")),
                  icon: _vm.ICONS.search,
                  name: "search",
                  "icon-to-right": "",
                  "data-test-id": "search_filters-input",
                },
                model: {
                  value: _vm.filters.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters, "search", $$v)
                  },
                  expression: "filters.search",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "col col-2 mb-3 d-flex align-items-center justify-content-center",
            },
            [
              _c("div", {
                staticClass: "font-xl d-inline-block icons-filter",
                attrs: { "data-test-id": "show_filters-button" },
                on: {
                  click: function ($event) {
                    _vm.showFiltersMobile = true
                  },
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "MuiCard",
        {
          class: [
            "d-flex flex-column flex-fill VehicleAllocationView__main-card",
            {
              "Availability__main-card--fullscreen": _vm.fullscreen,
              "position-relative": !_vm.fullscreen,
            },
          ],
          attrs: { "data-test-id": "main-card" },
        },
        [
          _vm.vehicleAllocationsStatus.LOADING || _vm.loading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex flex-column flex-fill" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "row no-gutters align-items-center d-none d-md-flex",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col col-md-4 col-lg-2 pr-3 mb-2" },
                    [
                      _c("MuiInputText", {
                        staticClass: "w-100 mb-2",
                        attrs: {
                          placeholder: _vm.upperFirst(
                            _vm.$t("Common.Actions.search")
                          ),
                          icon: _vm.ICONS.search,
                          name: "search",
                          "icon-to-right": "",
                          "data-test-id": "search_filters-input",
                        },
                        model: {
                          value: _vm.filters.search,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "search", $$v)
                          },
                          expression: "filters.search",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col col-md-4 col-lg-2 pr-3 mb-2" },
                    [
                      _c("MuiSelect", {
                        staticClass: "w-100 mb-2",
                        attrs: {
                          placeholder: _vm.upperFirst(
                            _vm.$t("Common.Business.cities")
                          ),
                          options: _vm.citiesToRequest,
                          name: "cities",
                          multiple: "",
                          "option-label": "name",
                          "option-value": "uuid",
                          "data-test-id": "cities-select",
                        },
                        model: {
                          value: _vm.filters.cities,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "cities", $$v)
                          },
                          expression: "filters.cities",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col col-md-4 col-lg-2 pr-3 mb-2" },
                    [
                      _c("MonthSelectorComponent", {
                        attrs: { "data-test-id": "date-select" },
                        model: {
                          value: _vm.filters.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "date", $$v)
                          },
                          expression: "filters.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col col-md-4 col-lg pr-3 mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-start mb-2" },
                      [
                        _c("ui-button-segments", {
                          staticClass: "Ui-ButtonSegments--minw-initial",
                          attrs: {
                            value: _vm.currentView(),
                            "default-color": _vm.GRAYSCALE.inkLight,
                            "data-test-id": "set_days-segmented_button",
                          },
                          domProps: {
                            options: _vm.map(
                              _vm.viewTypes,
                              ({ label }, index) => ({ label, value: index })
                            ),
                          },
                          on: {
                            clickbuttonsegment: (event) =>
                              _vm.setViewTypeByIndex(event.detail),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col col-md-8 col-lg-auto mb-2 d-flex justify-content-end",
                    },
                    [
                      _c("FullscreenButton", {
                        attrs: {
                          fullscreen: _vm.fullscreen,
                          "data-test-id": "fullscreen-button",
                        },
                        on: {
                          click: function ($event) {
                            _vm.fullscreen = !_vm.fullscreen
                          },
                        },
                      }),
                      _c(
                        "ui-button",
                        {
                          staticClass: "ml-2 wmin-initial mb-2",
                          attrs: {
                            "data-test-id":
                              "open_vehicle_allocation_form-button",
                          },
                          on: {
                            clickbutton: function ($event) {
                              return _vm.openVehicleAllocationForm()
                            },
                          },
                        },
                        [_vm._v(" Create allocation ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "MobileFiltersWrapperComponent",
                {
                  attrs: { "data-test-id": "mobile-filters" },
                  model: {
                    value: _vm.showFiltersMobile,
                    callback: function ($$v) {
                      _vm.showFiltersMobile = $$v
                    },
                    expression: "showFiltersMobile",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "filters" },
                    [
                      _c("AvailabilityMobileFilters", {
                        attrs: {
                          "cities-to-request": _vm.citiesToRequest,
                          "set-view-type": _vm.setViewTypeByIndex,
                          "data-test-id": "availability",
                        },
                        model: {
                          value: _vm.filters,
                          callback: function ($$v) {
                            _vm.filters = $$v
                          },
                          expression: "filters",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("DayPilot", {
                ref: "daypilot",
                staticClass: "d-flex flex-column full-height flex-fill",
                attrs: {
                  date: _vm.filters.date,
                  events: _vm.events,
                  resources: _vm.resources,
                  config: {
                    rowHeaderHideIconEnabled: true,
                  },
                  views: _vm.viewTypes,
                  "resources-bubble": _vm.resourcesBubble,
                  "on-row-filter": _vm.onRowFilter,
                  "on-event-clicked": _vm.onEventClick,
                  "on-time-range-selected": _vm.onTimeRangeSelected,
                  "on-time-range-selecting": _vm.onTimeRangeSelecting,
                  "script-url": "/js/daypilot-all.min.js",
                  "data-test-id": "daypilot",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isModalVisible
        ? _c("VehicleAllocationFormComponent", {
            attrs: {
              "vehicle-allocation": _vm.entityToEdit,
              initial: _vm.initialData,
              callback: _vm.onModalSuccessCallback,
              "close-modal": _vm.closeModal,
              "data-test-id": "vehicle_allocation-form",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
import { DATE_FORMAT } from '@emobg/web-utils';

export default {
  name: 'VehicleAllocationEventComponent',
  props: {
    vehicleAllocation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tooltipText() {
      const { vehicle } = this.vehicleAllocation;
      const vehicleTemplate = `${vehicle.license_plate} - ${vehicle.brand} ${vehicle.model}`;
      const start = this.moment(this.vehicleAllocation.start).format(DATE_FORMAT.defaultExtended);
      const end = this.moment(this.vehicleAllocation.end).format(DATE_FORMAT.defaultExtended);

      return `${vehicleTemplate} (${start} to ${end})`;
    },
  },
  created() {
    this.DATE_FORMAT = DATE_FORMAT;
  },
};
</script>
<template>
  <div
    class="VehicleAllocationEventComponent text-ellipsis"
    data-test-id="vehicle_allocation-event"
  >
    <div
      :title="tooltipText"
      class="text-ellipsis"
      data-test-id="tooltip"
    >
      <b class="emobg-font-weight-bold">
        {{ vehicleAllocation.vehicle.license_plate }} -
        {{ vehicleAllocation.vehicle.brand }} {{ vehicleAllocation.vehicle.model }}
      </b>
      (
      {{ moment(vehicleAllocation.start).format(DATE_FORMAT.defaultExtended) }}
      to
      {{ moment(vehicleAllocation.end).format(DATE_FORMAT.defaultExtended) }}
      )
    </div>
  </div>
</template>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._b(
      {
        staticClass: "DeleteUnavailabilityForm",
        attrs: {
          size: _vm.SIZES.small,
          "data-test-id": "unavailability_form-delete-modal",
        },
      },
      "GenericModalComponent",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("DeleteModalBodyComponent", {
            attrs: {
              text: _vm.$t("Common.Questions.delete_element", {
                element: _vm.$t("Common.Business.unavailability"),
              }),
              "data-test-id": "delete-modal",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: { click: _vm.closeModal },
          }),
          _c("DeleteButton", {
            attrs: { loading: _vm.isLoading, "data-test-id": "delete-button" },
            on: { click: _vm.removeUnavailability },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
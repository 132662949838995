<script>
import each from 'lodash/each';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';

import Vue from 'vue';
import moment from 'moment-timezone';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiCard, MuiInputText } from '@emobg/motion-ui/v1';
import { DATE_FORMAT } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  DayPilot,
  FullscreenButton,
  MobileFiltersWrapperComponent,
  MonthSelectorComponent,
} from '@/components';
import { DATE } from '@/components/DayPilot/constants';
import EventComponent from './components/Event/LongDistanceEventComponent';
import LongDistanceFormComponent from './components/Form/LongDistanceFormComponent';
import AvailabilityMobileFilters from '../AvailabilityMobileFilters/AvailabilityMobileFilters';

export default {
  components: {
    AvailabilityMobileFilters,
    LongDistanceFormComponent,
    DayPilot,
    FullscreenButton,
    MobileFiltersWrapperComponent,
    MonthSelectorComponent,
    MuiCard,
    MuiInputText,
  },
  data() {
    return {
      filters: {
        search: '',
        date: moment().startOf('month'),
        selectedView: '0',
      },
      showFiltersMobile: false,
      fullscreen: false,
      loading: true,
      resources: [],
      events: [],
      isModalFormOpen: false,
      initial: null,
      longDistanceSelected: null,
    };
  },
  computed: {
    filtersDate() {
      return this.filters.date.format(DATE_FORMAT.filter);
    },
    viewTypes() {
      return [
        {
          label: 'Month',
          config: {
            days: moment(this.filters.date).endOf('year').diff(moment(this.filters.date).startOf('year'), 'days') + 1,
            scale: 'Day',
            timeHeaders: [
              { groupBy: 'Month', format: 'MMM yyyy' },
              { groupBy: 'Day', format: 'dd' },
            ],
          },
        },
        {
          label: '3 Months',
          config: {
            days: moment(this.filters.date).endOf('month').add(2, 'months').diff(moment(this.filters.date).startOf('month'), 'days') + 1,
            scale: 'Month',
            timeHeaders: [
              { groupBy: 'Year', format: 'yyyy' },
              { groupBy: 'Month', format: 'MMMM' },
            ],
          },
        },
        {
          label: 'Year',
          config: {
            days: moment(this.filters.date).endOf('year').diff(moment(this.filters.date).startOf('year'), 'days') + 1,
            scale: 'Month',
            timeHeaders: [
              { groupBy: 'Year', format: 'yyyy' },
              { groupBy: 'Month', format: 'MMM' },
            ],
          },
        },
      ];
    },
    filterByOperator() {
      let filters = 'cs_operator_fk:undefined';

      if (!this.activeOperator) {
        return filters;
      }

      filters = `cs_operator_fk:${this.activeOperator.id}`;
      each(this.activeOperator.childrenIds, operatorChildId => {
        filters += ` OR cs_operator_fk:${operatorChildId}`;
      });

      return filters;
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperator: userState => get(userState, 'operators.active'),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.availability.longDistance.list, {
      longDistanceListStatus: state => state.STATUS,
      longDistanceListData: state => get(state, 'data.vehicles', []),
      longDistanceListErrors: state => state.error,
    }),
  },
  watch: {
    'filters.search': {
      handler() {
        if (!isNull(this.timeoutSearch)) {
          clearTimeout(this.timeoutSearch);
        }
        this.timeoutSearch = setTimeout(() => {
          this.$refs.daypilot.dp.rows.filter(this.filters);
        }, 300);
      },
      deep: true,
    },
    filtersDate(current, previous) {
      if (!isEqual(current, previous)) {
        this.listLongDistance();
      }
    },
    async filterByOperator() {
      this.resetStore();
      await this.listLongDistance();
    },
  },
  async mounted() {
    await this.listLongDistance();
    window.addEventListener('keyup', this.escapeListener);
    window.addEventListener('resize', this.resizeListener);
  },
  destroyed() {
    window.removeEventListener('keyup', this.escapeListener);
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    map,
    kebabCase,
    resizeListener() {
      const dayPilotShowRowHeaderElement = document.querySelector('.scheduler_default_header_icon_show');

      if (window.matchMedia('(max-width: 767px)').matches) {
        this.fullscreen = false;
      } else if (dayPilotShowRowHeaderElement) {
        dayPilotShowRowHeaderElement.click();
      }
    },
    escapeListener(e) {
      if (e.key === 'Escape') {
        this.fullscreen = false;
      }
    },
    currentView() {
      return (this.$refs.daypilot) ? this.$refs.daypilot.currentViewTypeIndex : 0;
    },
    setViewTypeByIndex(index) {
      this.$refs.daypilot.setCurrentViewTypeIndex(index);
      this.filters.selectedView = `${index}`;
    },

    openLongDistanceForm(longDistance, initial = null) {
      this.longDistanceSelected = longDistance;
      this.initial = initial;
      this.isModalFormOpen = true;
    },

    onRowFilter(element) {
      if (element.row.level === 0 && !element.row.data.html.toLowerCase().includes(element.filter.search.toLowerCase())) {
        element.visible = false;
      }
    },
    onTimeRangeSelecting(element) {
      if (moment(get(element, 'start.value'), DATE.detail).isBefore(moment())) {
        element.allowed = false;
        element.left.enabled = true;
        element.left.html = 'You can not add long distance before now';
      }
    },

    onTimeRangeSelected(element) {
      this.$refs.daypilot.dp.clearSelection();

      const start = moment(get(element, 'start.value'), DATE.detail);
      const end = moment(get(element, 'end.value'), DATE.detail).subtract(1, 'second');
      const row = this.$refs.daypilot.dp.rows.find(element.resource);

      if (!start.isBefore(moment())) {
        this.openLongDistanceForm(null, {
          start,
          end,
          vehicle_uuid: row.data.tags.vehicle.uuid,
        });
      }
    },

    onEventClick(element) {
      this.openLongDistanceForm(element.e.data.tags.vehicle);
    },

    async listLongDistance() {
      if (!isEmpty(this.activeOperator)) {
        await this.getLongDistanceList({
          start: this.filtersDate,
          end: moment(this.filters.date).add(1, 'year').format(DATE_FORMAT.filter),
          operatorUuid: this.activeOperator.uuid,
        });

        this.loading = false;
        if (this.longDistanceListStatus.LOADED) {
          this.getResourcesAndEvents()
            .then(response => {
              this.resources = response.resources;
              this.events = response.events;
            });
        } else if (this.longDistanceListStatus.ERROR) {
          this.$throwError(this.longDistanceListErrors);
          this.resources = [];
          this.events = [];
        }
      }
    },

    getResourcesAndEvents() {
      return new Promise(resolve => {
        const resourcesTransformed = [];
        const eventsTransformed = [];

        if ((isArray(this.longDistanceListData) && this.longDistanceListData.length > 0)) {
          this.longDistanceListData.forEach(vehicle => {
            resourcesTransformed.push(this.resourceTransformer(vehicle));

            vehicle.availability.forEach(vehicleAvailability => {
              eventsTransformed.push(this.eventTransformer(vehicleAvailability, {
                uuid: vehicle.uuid,
                license_plate: vehicle.license_plate,
                vehicle_brand: vehicle.vehicle_brand,
                vehicle_model: vehicle.vehicle_model,
              }));
            });
          });
        }

        resolve({
          resources: resourcesTransformed,
          events: eventsTransformed,
        });
      });
    },

    resourceTransformer(vehicle) {
      const vehicleUrl = `/fleet/vehicles/${vehicle.uuid}/details`;

      return {
        id: `V-${vehicle.uuid}`,
        html: `<a href="${vehicleUrl}" class="emobg-link-primary emobg-body-2" target="_blank">${vehicle.license_plate}</a>
            <br/>
            <span>
              ${vehicle.vehicle_brand} ${vehicle.vehicle_model}
            </span>`,
        tags: {
          vehicle,
        },
      };
    },

    eventTransformer(vAvailability, vehicle) {
      const Component = Vue.extend(EventComponent);
      const vehicleAvailability = vAvailability;
      vehicleAvailability.vehicle = vehicle;

      return {
        id: `VA-${vehicleAvailability.uuid}`,
        resource: `V-${vehicle.uuid}`,
        start: moment(vehicleAvailability.start, 'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00'),
        end: moment(vehicleAvailability.end, 'YYYY-MM-DD').format('YYYY-MM-DDT23:59:59'),
        html: new Component({
          propsData: {
            availability: vehicleAvailability,
          },
        }).$mount().$el.outerHTML,
        tags: {
          vehicle: vehicleAvailability,
        },
      };
    },
    ...mapActions(DOMAINS_MODEL.carsharing.availability.longDistance.list, [
      'getLongDistanceList',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.availability.longDistance.list, [
      'resetStore',
    ]),
  },
};
</script>
<template>
  <div
    class="LongDistanceView full-height d-flex flex-column flex-fill"
    data-test-id="long_distance-view"
  >
    <!-- MOBILE SUBHEADER -->
    <div
      class="LongDistanceView__mobileSubHeader row no-gutters px-2 d-flex d-md-none"
      data-test-id="mobile_sub_header"
    >
      <div class="col col-10">
        <MuiInputText
          v-model="filters.search"
          :icon="ICONS.search"
          icon-to-right
          name="search"
          placeholder="Search"
          class="w-100"
          data-test-id="search_filters-input"
        />
      </div>
      <div class="col col-2 mb-3 d-flex align-items-center justify-content-center">
        <div
          class="font-xl d-inline-block icons-filter"
          data-test-id="show_filters-button"
          @click="showFiltersMobile = true"
        />
      </div>
    </div>
    <!-- END MOBILE SUBHEADER -->

    <MuiCard
      :class="[
        'd-flex flex-column flex-fill LongDistanceView__main-card',
        {
          'Availability__main-card--fullscreen': fullscreen,
          'position-relative': !fullscreen,
        }
      ]"
      data-test-id="main-card"
    >
      <!-- LOADER -->
      <ui-loader
        v-if="longDistanceListStatus.LOADING || loading"
        absolute
        data-test-id="loader"
      />
      <!-- END LOADER -->
      <div class="d-flex flex-column flex-fill">
        <!-- FILTERS DESKTOP -->
        <div class="row no-gutters align-items-center d-none d-md-flex">
          <div class="col col-md-4 col-lg-2 pr-3">
            <MuiInputText
              v-model="filters.search"
              :icon="ICONS.search"
              icon-to-right
              name="search"
              placeholder="Search"
              class="w-100 mb-2"
              data-test-id="search_filters-input"
            />
          </div>
          <div class="col col-md-4 col-lg-2 pr-3">
            <MonthSelectorComponent
              v-model="filters.date"
              data-test-id="date_filters-select"
            />
          </div>
          <div class="col col-md-4 col-lg pr-2 mb-2">
            <div class="d-flex justify-content-start">
              <ui-button-segments
                :value="currentView()"
                :options.prop="map(viewTypes, ({ label }, index) => ({ label, value: index }))"
                :default-color="GRAYSCALE.inkLight"
                class="Ui-ButtonSegments--minw-initial"
                data-test-id="set_days-segmented_button"
                @clickbuttonsegment="event => setViewTypeByIndex(event.detail)"
              />
            </div>
          </div>

          <div
            class="
              d-flex justify-content-end
              col col-md-12 col-lg-auto
              mb-2
            "
          >
            <FullscreenButton
              :fullscreen="fullscreen"
              data-test-id="fullscreen-button"
              @click="fullscreen = !fullscreen"
            />

            <ui-button
              class="ml-2 wmin-initial"
              data-test-id="open_form-button"
              @clickbutton="openLongDistanceForm()"
            >
              Create Long Distance
            </ui-button>
          </div>
        </div>
        <!-- END FILTERS DESKTOP -->

        <!-- FILTERS MOBILE -->
        <MobileFiltersWrapperComponent
          v-model="showFiltersMobile"
          data-test-id="mobile-filters"
        >
          <template slot="filters">
            <AvailabilityMobileFilters
              v-model="filters"
              :set-view-type="setViewTypeByIndex"
              data-test-id="availability"
            />
          </template>
        </MobileFiltersWrapperComponent>
        <!-- END FILTERS MOBILE -->

        <!-- SCHEDULER -->
        <DayPilot
          ref="daypilot"
          :date="filters.date"
          :events="events"
          :resources="resources"
          :config="{
            rowHeaderHideIconEnabled: true,
          }"
          :views="viewTypes"
          :on-row-filter="onRowFilter"
          :on-event-clicked="onEventClick"
          :on-time-range-selecting="onTimeRangeSelecting"
          :on-time-range-selected="onTimeRangeSelected"
          class="d-flex flex-column full-height flex-fill"
          script-url="/js/daypilot-all.min.js"
          data-test-id="daypilot"
        />
        <!-- END SCHEDULER -->
      </div>
    </MuiCard>

    <LongDistanceFormComponent
      v-if="isModalFormOpen"
      :long-distance="longDistanceSelected"
      :initial="initial"
      :callback="listLongDistance"
      data-test-id="long_distance-form"
      @closeModal="() => isModalFormOpen = false"
    />
  </div>
</template>

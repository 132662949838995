<script>
import kebabCase from 'lodash/kebabCase';
import get from 'lodash/get';
import map from 'lodash/map';
import head from 'lodash/head';
import findIndex from 'lodash/findIndex';
import invoke from 'lodash/invoke';
import includes from 'lodash/includes';

import { mapMutations } from 'vuex';

import { MuiTabs } from '@emobg/vue-base';
import { navigationErrorHandler } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import carsharing from '@domains/Carsharing/router/CarsharingRouterMap';
import { PageView } from '@/components';
import VehicleAllocationForm from './components/VehicleAllocation/VehicleAllocationForm/VehicleAllocationFormComponent';
import LongDistanceForm from './components/LongDistance/components/Form/LongDistanceFormComponent';
import UnavailabilityFormComponent from './components/Unavailabilities/components/Form/UnavailabilityFormComponent';

export default {
  components: {
    MuiTabs,
    PageView,
  },
  data() {
    return {
      tabs: [],
      isAvailabilityFormVisible: false,
    };
  },
  computed: {
    activeTabIndex() {
      const { path } = this.$route;
      const tabIndex = findIndex(this.tabs, tab => includes(path, tab.url));

      return tabIndex < 0 ? 0 : tabIndex;
    },
    activeTab() {
      return (this.tabs && this.tabs.length) && (this.tabs[this.activeTabIndex] || head(this.tabs));
    },
    availabilityActionLabel() {
      return (this.activeTab && this.activeTab.actionLabel) || `Create ${this.activeTab.label}`;
    },
    availabilityForm() {
      return this.activeTab && this.activeTab.formComponent;
    },
    availabilityActionMethod() {
      return this.activeTab && this.activeTab.action;
    },
    availabilityActions() {
      return [{ label: this.availabilityActionLabel, action: () => this.openCurrentCreationForm() }];
    },
  },
  mounted() {
    this.tabs = [
      {
        label: 'Unavailabilities',
        url: 'unavailabilities',
        tooltip: 'Make vehicles unavailable. Unavailable vehicles cannot be booked.',
        formComponent: UnavailabilityFormComponent,
        callback: () => {
          this.closeModal();
          this.refreshUnavailabilityList(true);
        },
      },
      {
        label: 'Vehicle Allocation',
        url: 'vehicle-allocation',
        tooltip: 'Allocate vehicles to a specific home parking, zone, etc. Only allocated vehicles can be booked.',
        formComponent: VehicleAllocationForm,
        callback: () => {
          this.closeModal();
          this.refreshVehicleAllocations(true);
        },
      },
      {
        label: 'Long Distance',
        url: 'long-distance',
        tooltip: 'Make vehicles available for Long Distance bookings',
        formComponent: LongDistanceForm,
        callback: () => {
          this.closeModal();
          this.refreshLongDistanceList(true);
        },
      },
      {
        label: 'Booking Sets',
        url: 'booking-sets',
        tooltip: 'Create multiple bookings on several vehicles in different times.',
        actionLabel: 'Create booking set',
        action: () => {
          this.$router.push({ name: carsharing.bookingSets.create }).catch(navigationErrorHandler);
        },
      },
    ];
  },
  methods: {
    get,
    kebabCase,
    invoke,
    closeModal() {
      this.isAvailabilityFormVisible = false;
    },
    openCurrentCreationForm() {
      if (this.activeTab.action) {
        this.activeTab.action();
      } else if (this.availabilityForm) {
        this.isAvailabilityFormVisible = true;
      }
    },
    activateTab(url) {
      this.$router.push({ path: url }).catch(navigationErrorHandler);
    },
    tabsSelection() {
      return map(this.tabs, ({ label, url }) => ({ label, value: url }));
    },
    ...mapMutations(DOMAINS_MODEL.carsharing.availability.vehicleAllocations.list, {
      refreshVehicleAllocations: 'setRefreshDayPilot',
    }),
    ...mapMutations(DOMAINS_MODEL.carsharing.availability.longDistance.list, {
      refreshLongDistanceList: 'setRefreshDayPilot',
    }),
    ...mapMutations(DOMAINS_MODEL.carsharing.availability.unavailability.list, {
      refreshUnavailabilityList: 'setRefreshDaypilot',
    }),
  },
};
</script>

<template>
  <div
    class="AvailabilityView d-flex flex-column flex-fill"
    data-test-id="availability-view"
  >
    <PageView class="emobg-background-color-white">
      <MuiTabs
        id="AvailabilityTabs"
        :tabs="tabs"
        :active="activeTabIndex"
        class="emobg-background-color-white"
        @active-tab="activateTab"
      />
    </PageView>
    <PageView
      is-tab-content
      class="d-flex flex-column flex-fill AvailabilityView__content"
    >
      <div class="d-flex d-md-none">
        <div class="col col-10">
          <ui-select
            :value="get(tabs[activeTabIndex], 'url', '')"
            :options.prop="tabsSelection"
            class="w-100 mb-2"
            required
            name="items"
            option-value="url"
            data-test-id="select"
            @selectoption="({ detail }) => activateTab(detail)"
          />
        </div>
        <div class="col col-2 mb-3 d-flex align-items-center justify-content-center">
          <ui-dropdown :key="availabilityActionLabel">
            <ui-button
              slot="trigger"
              :color="GRAYSCALE.white"
              contrast
              square
            >
              <ui-icon
                :color="GRAYSCALE.inkLight"
                :icon="ICONS.optionsHorizontalFull"
              />
            </ui-button>
            <ui-dropdown-actions
              slot="content"
              :actions.prop="availabilityActions"
              data-test-id="list"
            />
          </ui-dropdown>
        </div>
      </div>
      <div class="d-md-flex justify-content-between d-none mb-2">
        <div class="d-flex align-items-center">
          <h2>
            {{ get(activeTab, 'label') }}
          </h2>
          <ui-tooltip
            no-arrow
            :tooltip="get(activeTab, 'tooltip')"
            class="ml-1"
            data-test-id="tooltip"
          >
            <ui-icon
              :icon="ICONS.infoFull"
              :size="ICONS_SIZES.small"
              class="emobg-color-ink-light emobg-color-ink-hover"
              data-test-id="icon"
            />
          </ui-tooltip>
        </div>
        <ui-button
          v-if="get(activeTab, 'action')"
          data-test-id="create-button"
          @clickbutton="() => invoke(activeTab, 'action')"
        >
          {{ availabilityActionLabel }}
        </ui-button>
      </div>
      <RouterView />
    </PageView>
    <Component
      :is="availabilityForm"
      v-if="availabilityForm && isAvailabilityFormVisible"
      :callback="get(activeTab, 'callback')"
      :close-modal="closeModal"
      :title="availabilityActionLabel"
      data-test-id="form"
      @closeModal="closeModal"
    />
  </div>
</template>

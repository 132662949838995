<script>
import get from 'lodash/get';
import omit from 'lodash/omit';
import isNull from 'lodash/isNull';
import { toRefs } from 'vue';
import moment from 'moment-timezone';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { MuiDatePicker } from '@emobg/vue-base';
import { DATE_FORMAT } from '@emobg/web-utils';
import {
  initialDatesValidation,
  useAvailabilityDates,
} from '@domains/Carsharing/Availability/composable/AvailabilityDates';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent, RemoveButton } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { DATE_INPUT_VALIDATIONS } from '@/utils';
import RemoveForm from './Remove/RemoveFormComponent';

export default {
  name: 'LongDistanceFormComponent',
  components: {
    CancelButton,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiDatePicker,
    MuiValidationWrapper,
    RemoveButton,
    RemoveForm,
  },
  directives: {
    Validate,
  },
  props: {
    longDistance: {
      type: Object,
      default: null,
    },
    initial: {
      type: Object,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const { longDistance, initial } = toRefs(props);
    let startDefault;
    let endDefault;

    if (longDistance.value) {
      startDefault = moment(longDistance.value.start);
      endDefault = moment(longDistance.value.end);
    } else if (initial.value) {
      const validatedDates = initialDatesValidation(initial.value.start, initial.value.end);
      startDefault = validatedDates.start;
      endDefault = validatedDates.end;
    } else {
      startDefault = moment().add(1, 'day').startOf('day');
      endDefault = moment().add(1, 'day').startOf('day').add(6, 'months');
    }

    const initialAvailabilityDatesData = {
      start: startDefault,
      end: endDefault,
      minStartDefault: () => moment().add(1, 'day').startOf('day'),
      entity: longDistance.value,
    };

    const {
      start,
      end,
      isEntityFinished,
      isEntityStarted,
      setStart,
      setEnd,
    } = useAvailabilityDates(initialAvailabilityDatesData);

    return {
      start,
      end,
      isEntityFinished,
      isEntityStarted,
      setStart,
      setEnd,
    };
  },
  data() {
    return {
      inputs: {
        vehicle_uuid: null,
      },
      uuid: null,
      isRemoveModalVisible: false,
      isFormValid: false,
    };
  },
  computed: {
    modalTitle() {
      const element = 'Long Distance';
      return this.longDistance
        ? this.$t('Common.Actions.edit_element', { element })
        : this.$t('Common.Actions.create_element', { element });
    },
    dataToSend() {
      return {
        vehicle_uuid: this.inputs.vehicle_uuid,
        start: this.start && this.start.isValid() ? this.start.format(DATE_FORMAT.date) : null,
        end: this.end && this.end.isValid() ? this.end.format(DATE_FORMAT.date) : null,
        product: 'long_distance',
      };
    },
    areAllRequiredFieldsFilled() {
      return this.inputs.vehicle_uuid && this.isFormValid;
    },
    ...mapState(DOMAINS_MODEL.carsharing.availability.longDistance.detail, {
      longDistanceDetailStatus: state => state.STATUS,
      longDistanceDetailData: state => state.data,
      longDistanceDetailErrors: state => state.error,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.DATE_INPUT_VALIDATIONS = DATE_INPUT_VALIDATIONS;
    this.VEHICLES_INDEX = ALGOLIA_INDEXES.vehicles;
    this.inputs.vehicle_uuid = get(this.longDistance, 'vehicle.uuid') || get(this.initial, 'vehicle_uuid');
    this.uuid = get(this, 'longDistance.uuid', null);
  },
  methods: {
    isNull,
    async createLongDistance() {
      await this.addLongDistance(this.dataToSend);
      this.afterRequest();
    },
    async editLongDistance() {
      await this.updateLongDistance({
        uuid: this.uuid,
        longDistance: (this.isEntityStarted) ? omit(this.dataToSend, ['start']) : this.dataToSend,
      });
      this.afterRequest();
    },
    afterRequest() {
      if (this.longDistanceDetailStatus.LOADED) {
        this.$emit('closeModal');
        this.callback(this.longDistanceDetailData);
      } else if (this.longDistanceDetailStatus.ERROR) {
        this.$throwError(this.longDistanceDetailErrors);
      }
    },
    onSuccessRemoveLongDistance() {
      this.afterRequest();
      this.$emit('closeModal');
    },
    ...mapActions(DOMAINS_MODEL.carsharing.availability.longDistance.detail, [
      'addLongDistance',
      'updateLongDistance',
    ]),
  },
};
</script>
<template>
  <div
    class="LongDistanceFormComponent"
    data-test-id="long_distance-form"
  >
    <GenericModalComponent
      v-if="!isRemoveModalVisible"
      v-bind="$attrs"
      :title="modalTitle"
    >
      <template slot="body">
        <MuiValidationWrapper
          class="row"
          @areAllValid="isValid => isFormValid = isValid"
        >
          <div class="col-12 mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.vehicle_uuid"
              :index="VEHICLES_INDEX"
              :filters="`${getOperatorFilter({ index: VEHICLES_INDEX })} AND non_connected:0`"
              :disabled="!isNull(longDistance)"
              :title="(res) => `${res.license_plate} - ${res.vehicle_brand} ${res.vehicle_model}`"
              label="Vehicle*"
              name="vehicle_uuid"
              class="w-100"
              path-value="uuid"
              placeholder="Select vehicle"
              data-test-id="vehicle-select"
            />
          </div>
          <div class="col-12 col-sm-6 mb-4 mb-sm-0">
            <MuiDatePicker
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date="start"
              :disabled="isEntityStarted"
              data-test-id="start_date-select"
              skip-time-step
              label="Start date*"
              class="w-100"
              @update:date="setStart"
            />
          </div>
          <div class="col-12 col-sm-6">
            <MuiDatePicker
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date="end"
              :disabled="isEntityFinished"
              data-test-id="end_date-select"
              skip-time-step
              label="End date*"
              class="w-100"
              @update:date="setEnd"
            />
          </div>
        </MuiValidationWrapper>
      </template>
      <template slot="footer">
        <div class="d-flex flex-fill align-content-center justify-content-between">
          <div class="d-flex justify-content-center">
            <RemoveButton
              v-if="longDistance && !isEntityStarted"
              :disabled="longDistanceDetailStatus.LOADING"
              data-test-id="remove-button"
              @click="() => isRemoveModalVisible = true"
            />
          </div>

          <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
            <div class="d-flex justify-content-center">
              <CancelButton
                data-test-id="cancel-button"
                @click="$emit('closeModal')"
              />
            </div>

            <div class="d-flex justify-content-center">
              <ui-button
                :disabled="!areAllRequiredFieldsFilled"
                :loading="longDistanceDetailStatus.LOADING"
                class="wmin-initial"
                data-test-id="edit_create-button"
                @clickbutton="longDistance ? editLongDistance() : createLongDistance()"
              >
                {{ longDistance ? 'Save' : 'Create' }}
              </ui-button>
            </div>
          </div>
        </div>
      </template>
    </GenericModalComponent>
    <RemoveForm
      v-if="isRemoveModalVisible"
      :header="null"
      :long-distance="longDistance"
      :callback="onSuccessRemoveLongDistance"
      data-test-id="remove-form"
      @closeModal="() => isRemoveModalVisible = false"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { DATE_FORMAT } from '@emobg/web-utils';

export default {
  name: 'LongDistanceEventComponent',
  props: {
    availability: {
      type: Object,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
    DATE_FORMAT: () => DATE_FORMAT,
    tooltipText() {
      const start = moment(this.availability.start).format(DATE_FORMAT.dob);
      const end = moment(this.availability.end).format(DATE_FORMAT.dob);

      return `Long Distance (${start} to ${end})`;
    },
  },
};
</script>
<template>
  <div
    class="LongDistanceEventComponent text-ellipsis"
    data-test-id="long_distance-event"
  >
    <div
      :title="tooltipText"
      class="text-ellipsis"
      data-test-id="tooltip"
    >
      <b class="emobg-font-weight-bold">
        Long Distance
      </b>
      (
      {{ moment(availability.start).format(DATE_FORMAT.dob) }}
      to
      {{ moment(availability.end).format(DATE_FORMAT.dob) }}
      )
    </div>
  </div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import each from 'lodash/each';
import get from 'lodash/get';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import includes from 'lodash/includes';
import clone from 'lodash/clone';
import unset from 'lodash/unset';
import kebabCase from 'lodash/kebabCase';
import size from 'lodash/size';

import moment from 'moment-timezone'; // Import necessary for `data` hook

import Vue from 'vue';
import { DATE_FORMAT, KEYNAME } from '@emobg/web-utils';
import { MuiCard, MuiInputText, MuiSelect } from '@emobg/motion-ui/v1';
import { mapActions, mapMutations, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import {
  DayPilot,
  EmptyStateComponent,
  FullscreenButton,
  MobileFiltersWrapperComponent,
  MonthSelectorComponent,
} from '@/components';
import { DATE } from '@/components/DayPilot/constants';
import UnavailabilityFormComponent from './components/Form/UnavailabilityFormComponent';
import EventComponent from './components/Event/UnavailabilityEventComponent';
import AvailabilityModalMixin from '../../mixins/AvailabilityModalMixin';
import AvailabilityMobileFilters from '../AvailabilityMobileFilters/AvailabilityMobileFilters';

export default {
  components: {
    EmptyStateComponent,
    AvailabilityMobileFilters,
    DayPilot,
    FullscreenButton,
    MobileFiltersWrapperComponent,
    MonthSelectorComponent,
    MuiCard,
    MuiInputText,
    UnavailabilityFormComponent,
    MuiSelect,
  },
  mixins: [AvailabilityModalMixin],
  data() {
    return {
      resources: [],
      events: [],
      citiesToRequest: [],
      filters: {
        date: moment().startOf('month'),
        cities: [],
        search: '',
        selectedView: '0',
      },
      timeoutFilters: null,
      showFiltersMobile: false,
      fullscreen: false,
      loading: true,
    };
  },
  computed: {
    viewTypes() {
      return [
        {
          label: upperFirst(this.$t('Common.Time.month')),
          config: {
            days: this.moment(this.filters.date).endOf('year').diff(this.moment(this.filters.date).startOf('year'), 'days') + 1,
            scale: 'Day',
            timeHeaders: [
              { groupBy: 'Month', format: 'MMM yyyy' },
              { groupBy: 'Day', format: 'dd' },
            ],
          },
        },
        {
          label: this.$t('Common.Time.months_number', { number: 3 }),
          config: {
            days: this.moment(this.filters.date).endOf('month').add(2, 'months').diff(this.moment(this.filters.date).startOf('month'), 'days') + 1,
            scale: 'Month',
            timeHeaders: [
              { groupBy: 'Year', format: 'yyyy' },
              { groupBy: 'Month', format: 'MMMM' },
            ],
          },
        },
        {
          label: upperFirst(this.$t('Common.Time.year')),
          config: {
            days: this.moment(this.filters.date).endOf('year').diff(this.moment(this.filters.date).startOf('year'), 'days') + 1,
            scale: 'Month',
            timeHeaders: [
              { groupBy: 'Year', format: 'yyyy' },
              { groupBy: 'Month', format: 'MMM' },
            ],
          },
        },
      ];
    },
    filterByOperator() {
      let filters = 'cs_operator_fk:undefined';

      if (!this.activeOperator) {
        return filters;
      }

      filters = `cs_operator_fk:${this.activeOperator.id}`;
      each(this.activeOperator.childrenIds, operatorChildId => {
        filters += ` OR cs_operator_fk:${operatorChildId}`;
      });

      return filters;
    },
    citiesToRequestUUIDs() {
      return map(this.citiesToRequest, item => item.uuid);
    },
    filtersDate() {
      return this.filters.date.format(DATE_FORMAT.date);
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperator: userState => get(userState, 'operators.active'),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.availability.unavailability.list, {
      unavailabilityListStatus: state => state.STATUS,
      unavailabilityListData: state => state.data,
      unavailabilityListErrors: state => state.error,
      unavailabilityListRefreshDaypilot: state => state.refreshDaypilot,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.cities, {
      citiesData: state => state.data,
      citiesStatus: state => state.STATUS,
    }),
  },
  watch: {
    'filters.search': {
      handler() {
        this.handleDpFilters();
      },
      deep: true,
    },
    'filters.cities': {
      handler() {
        this.handleDpFilters();
      },
      deep: true,
    },
    filtersDate(val, old) {
      this.compareValues(val, old);
    },
    citiesToRequestUUIDs(val, old) {
      this.compareValues(val, old);
    },
    filterByOperator() {
      this.resetStore();
      this.setCitiesToRequest();
    },
    citiesData(value) {
      if (isArray(value) && value.length) {
        this.setCitiesToRequest();
      }
    },
  },
  mounted() {
    this.setCitiesToRequest();
    window.addEventListener('keyup', this.escapeListener);
    window.addEventListener('resize', this.resizeListener);
  },
  destroyed() {
    window.removeEventListener('keyup', this.escapeListener);
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    map,
    kebabCase,
    size,
    upperFirst,
    resizeListener() {
      if (window.matchMedia('(max-width: 767px)').matches) {
        this.fullscreen = false;
      }
    },
    escapeListener(event) {
      if (event.key === KEYNAME.ESCAPE) {
        this.fullscreen = false;
      }
    },
    compareValues(val, old) {
      if (!isEqual(val, old)) {
        this.listUnavailabilities();
        this.resetFilters();
      }
    },
    resetFilters() {
      this.filters.cities = cloneDeep(this.citiesToRequestUUIDs);
      this.filters.search = '';
    },
    handleDpFilters() {
      if (this.$refs.daypilot && this.$refs.daypilot.dp) {
        if (this.timeoutSearch !== null) {
          clearTimeout(this.timeoutSearch);
        }
        this.timeoutSearch = setTimeout(() => {
          try {
            this.$refs.daypilot.dp.rows.filter(this.filters);
          } catch (e) {
            //
          }
        }, 300);
      }
    },
    onTimeRangeSelecting(element) {
      if (this.moment(element.start, DATE.detail).isBefore(this.moment())) {
        element.allowed = false;
        element.left.enabled = true;
        element.left.html = this.$t('Common.Errors.no_element_addition_before_now', { element: upperFirst(this.$t('Common.Business.unavailability')) });
      }
    },
    onTimeRangeSelected(element) {
      this.$refs.daypilot.dp.clearSelection();
      const start = this.moment(element.start, DATE.detail);
      const end = this.moment(element.end, DATE.detail).subtract(1, 'seconds');
      if (!start.isBefore(this.moment())) {
        this.openUnavailabilityForm(null, {
          start,
          end,
          vehicle_uuid: element.resource,
        });
      }
    },
    onEventClick(element) {
      this.openUnavailabilityForm({
        uuid: element.e.data.id,
        start: this.moment(element.e.data.start, DATE.detail),
        end: this.moment(element.e.data.end, DATE.detail),
        vehicle_uuid: element.e.data.tags.booking.vehicle_uuid,
      });
    },
    onRowFilter(element) {
      const isPlateIncluded = lp => lp.toLowerCase().includes(element.filter.search.toLowerCase());
      if (element.row.level === 0
        && (
          !element.row.data.html.toLowerCase().includes(element.filter.search.toLowerCase())
          && findIndex(element.row.data.tags.license_plates, isPlateIncluded) === -1
        )
      ) {
        element.visible = false;
      }

      // Cities
      if (this.filters.cities.length > 0) {
        const cityToSearch = find(this.filters.cities, cityUuid => includes(element.row.data.tags.vehicle.cities, cityUuid));

        if (!cityToSearch) {
          element.visible = false;
        }
      } else {
        element.visible = false;
      }
    },
    currentView() {
      return (this.$refs.daypilot) ? this.$refs.daypilot.currentViewTypeIndex : 0;
    },
    setViewTypeByIndex(index) {
      this.$refs.daypilot.setCurrentViewTypeIndex(index);
      this.filters.selectedView = `${index}`;
    },
    onModalSuccessCallback() {
      this.closeModal();
      this.listUnavailabilities();
      this.resetFilters();
    },
    openUnavailabilityForm(unavailability, initial = null) {
      this.entityToEdit = unavailability;
      this.initialData = initial;
      this.isModalVisible = true;
    },
    setCitiesToRequest() {
      this.citiesToRequest = this.citiesData;
    },
    resourceTransformer(vehicle) {
      const vehicleClone = clone(vehicle);
      unset(vehicleClone, 'bookings');

      const vehicleUrl = `/fleet/vehicles/${vehicle.uuid}/details`;

      return {
        id: `${vehicle.uuid}`,
        html: `<a href="${vehicleUrl}" class="emobg-link-primary emobg-body-2" target="_blank">${vehicle.license_plate}</a>
          <br/>
          <span>
            ${vehicle.vehicle_brand} ${vehicle.vehicle_model}
          </span>`,
        tags: {
          vehicle: vehicleClone,
        },
      };
    },
    eventTransformer(booking) {
      const Component = Vue.extend(EventComponent);

      return {
        id: booking.uuid,
        uuid: booking.uuid,
        resource: `${booking.vehicle_uuid}`,
        start: booking.start.replace(' ', 'T'),
        end: booking.end.replace(' ', 'T'),
        html: new Component({
          propsData: {
            unavailability: booking,
          },
        }).$mount().$el.outerHTML,
        tags: {
          booking,
        },
      };
    },
    getResourcesAndEvents() {
      return new Promise(resolve => {
        const r = {
          resources: [],
          events: [],
        };

        if (isArray(this.unavailabilityListData) && this.unavailabilityListData.length > 0) {
          this.unavailabilityListData.forEach(vehicle => {
            const vehicleWithCities = vehicle;
            vehicleWithCities.cities = [];

            vehicle.bookings.forEach(booking => {
              vehicleWithCities.cities.push(booking.city_uuid);
              r.events.push(this.eventTransformer(booking));
            });

            r.resources.push(this.resourceTransformer(vehicleWithCities));
          });
        }

        resolve(r);
      });
    },

    async listUnavailabilities() {
      await this.getUnavailabilityList({
        city_uuids: this.citiesToRequestUUIDs,
        start: this.filtersDate,
        end: this.moment(this.filters.date).add(1, 'years').format('YYYY-MM-DD'),
      });

      this.loading = false;
      if (this.unavailabilityListStatus.LOADED) {
        this.getResourcesAndEvents()
          .then(response => {
            this.resources = response.resources;
            this.events = response.events;
          });
      } else if (this.unavailabilityListStatus.ERROR) {
        this.$throwError(this.unavailabilityListErrors);
      }
    },
    ...mapActions(DOMAINS_MODEL.carsharing.availability.unavailability.list, [
      'getUnavailabilityList',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.availability.unavailability.list, [
      'resetStore',
    ]),
  },
};
</script>

<template>
  <div
    class="UnavailabilityView full-height d-flex flex-column flex-fill"
    data-test-id="unavailability-view"
  >
    <!-- MOBILE SUBHEADER -->
    <div
      class="UnavailabilityView__mobileSubHeader row no-gutters px-2 d-flex d-md-none"
      data-test-id="mobile_sub_header"
    >
      <div class="col col-10">
        <MuiInputText
          v-model="filters.search"
          :placeholder="upperFirst($t('Common.Actions.search'))"
          :icon="ICONS.search"
          class="w-100 mb-2"
          name="search"
          icon-to-right
          data-test-id="search_filters-input"
        />
      </div>
      <div class="col col-2 mb-3 d-flex align-items-center justify-content-center">
        <div
          class="font-xl d-inline-block icons-filter"
          data-test-id="show_filters-button"
          @click="showFiltersMobile = true"
        />
      </div>
    </div>
    <!-- END MOBILE SUBHEADER -->

    <!-- MAIN CARD -->
    <MuiCard
      :class="[
        'd-flex flex-column flex-fill UnavailabilityView__main-card',
        {
          'Availability__main-card--fullscreen': fullscreen,
          'position-relative': !fullscreen,
        }
      ]"
      data-test-id="main-card"
    >
      <!-- LOADER -->
      <ui-loader
        v-if="unavailabilityListStatus.LOADING || loading"
        absolute
        data-test-id="loader"
      />
      <!-- END LOADER -->
      <div class="d-flex flex-column flex-fill">
        <!-- FILTERS DESKTOP -->
        <div class="row no-gutters align-items-center d-none d-md-flex">
          <div class="col col-md-4 col-lg-2 pr-3">
            <MuiInputText
              v-model="filters.search"
              :placeholder="upperFirst($t('Common.Actions.search'))"
              :icon="ICONS.search"
              class="w-100 mb-2"
              name="search"
              icon-to-right
              data-test-id="search_filters-input"
            />
          </div>
          <div class="col col-md-4 col-lg-2 pr-3">
            <MuiSelect
              v-model="filters.cities"
              :placeholder="upperFirst($t('Common.Business.cities'))"
              :options="citiesToRequest"
              name="cities"
              multiple
              class="w-100 mb-2"
              option-label="name"
              option-value="uuid"
              data-test-id="cities-select"
            />
          </div>
          <div class="col col-md-4 col-lg-2 pr-3">
            <MonthSelectorComponent
              v-model="filters.date"
              data-test-id="date-select"
            />
          </div>
          <div class="col col-md-4 col-lg pr-2">
            <div class="d-flex justify-content-start mb-2">
              <ui-button-segments
                :value="currentView()"
                :options.prop="map(viewTypes, ({ label }, index) => ({ label, value: index }))"
                :default-color="GRAYSCALE.inkLight"
                class="Ui-ButtonSegments--minw-initial"
                data-test-id="set_days-segmented_button"
                @clickbuttonsegment="event => setViewTypeByIndex(event.detail)"
              />
            </div>
          </div>
          <div class="col col-md-8 col-lg-auto mb-2 d-flex justify-content-end">
            <FullscreenButton
              :fullscreen="fullscreen"
              data-test-id="fullscreen_button"
              @click="fullscreen = !fullscreen"
            />

            <ui-button
              class="ml-2 wmin-initial"
              data-test-id="unavailability_form-open-button"
              @clickbutton="openUnavailabilityForm()"
            >
              {{ $t('Common.Actions.create_element', { element: $t('Common.Business.unavailability')}) }}
            </ui-button>
          </div>
        </div>
        <!-- END FILTERS DESKTOP -->

        <!-- FILTERS MOBILE -->
        <MobileFiltersWrapperComponent
          v-model="showFiltersMobile"
          data-test-id="mobile-filters"
        >
          <template slot="filters">
            <AvailabilityMobileFilters
              v-model="filters"
              :cities-to-request="citiesToRequest"
              :set-view-type="setViewTypeByIndex"
              data-test-id="avalability"
            />
          </template>
        </MobileFiltersWrapperComponent>
        <!-- END FILTERS MOBILE -->

        <!-- SCHEDULER -->
        <DayPilot
          v-if="size(events)"
          ref="daypilot"
          :date="filters.date"
          :events="events"
          :resources="resources"
          :config="{
            rowHeaderHideIconEnabled: true,
          }"
          :views="viewTypes"
          :on-row-filter="onRowFilter"
          :on-event-clicked="onEventClick"
          :on-time-range-selected="onTimeRangeSelected"
          :on-time-range-selecting="onTimeRangeSelecting"
          class="d-flex flex-column full-height flex-fill"
          script-url="/js/daypilot-all.min.js"
          data-test-id="daypilot"
        />
        <EmptyStateComponent
          v-else
          has-background
          has-border
          title="You haven’t created any unavailability yet"
          class="py-6"
        />
        <!-- END SCHEDULER -->
      </div>
    </MuiCard>

    <UnavailabilityFormComponent
      v-if="isModalVisible"
      :unavailability="entityToEdit"
      :initial="initialData"
      :callback="onModalSuccessCallback"
      :close-modal="closeModal"
      data-test-id="form"
    />
  </div>
</template>

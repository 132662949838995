<script>
import get from 'lodash/get';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';

import { toRefs } from 'vue';
import moment from 'moment-timezone';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { MuiDatePicker } from '@emobg/vue-base';
import { DATE_FORMAT, DATE_FORMAT_KEYS } from '@emobg/web-utils';
import {
  initialDatesValidation,
  useAvailabilityDates,
} from '@domains/Carsharing/Availability/composable/AvailabilityDates';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, DeleteButton, GenericModalComponent } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { DATE_INPUT_VALIDATIONS } from '@/utils';
import RemoveUnavailabilityFormComponent from './Remove/RemoveFormComponent';

export default {
  name: 'UnavailabilityFormComponent',
  components: {
    MuiAlgoliaSelect,
    CancelButton,
    MuiDatePicker,
    MuiValidationWrapper,
    DeleteButton,
    GenericModalComponent,
    RemoveUnavailabilityFormComponent,
  },
  directives: {
    Validate,
  },
  props: {
    unavailability: {
      type: Object,
      default: null,
    },
    initial: {
      type: Object,
      default: null,
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const { unavailability, initial } = toRefs(props);
    let startDefault;
    let endDefault;

    if (unavailability.value) {
      startDefault = moment(unavailability.value.start);
      endDefault = moment(unavailability.value.end);
    } else if (initial.value) {
      const validatedDates = initialDatesValidation(initial.value.start, initial.value.end);
      startDefault = validatedDates.start;
      endDefault = validatedDates.end;
    } else {
      startDefault = moment();
      endDefault = moment().add(1, 'months').startOf('day');
    }

    const initialAvailabilityDatesData = {
      start: startDefault,
      end: endDefault,
      minStartDefault: () => moment(),
      entity: unavailability.value,
    };

    const {
      start,
      end,
      isEntityFinished,
      isEntityStarted,
      setStart,
      setEnd,
    } = useAvailabilityDates(initialAvailabilityDatesData);

    return {
      start,
      end,
      isEntityFinished,
      isEntityStarted,
      setStart,
      setEnd,
    };
  },
  data() {
    return {
      DATE_FORMAT,
      inputs: {
        vehicle_uuid: null,
        comment: '',
      },
      uuid: undefined,
      isRemoveModalVisible: false,
      isFormValid: false,
    };
  },
  computed: {
    modalTitle() {
      const element = this.$t('Common.Business.unavailability');
      return this.unavailability
        ? this.$t('Common.Actions.edit_element', { element })
        : this.$t('Common.Actions.create_element', { element });
    },
    dataToSend() {
      return {
        ...this.inputs,
        start: this.start && this.start.format(DATE_FORMAT.defaultExtended),
        end: this.end && this.end.format(DATE_FORMAT.defaultExtended),
      };
    },
    areAllRequiredFieldsFilled() {
      return (this.inputs.vehicle_uuid && this.isFormValid);
    },
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    ...mapState(DOMAINS_MODEL.carsharing.availability.unavailability.detail, {
      status: state => state.STATUS,
      unavailabilityDetailData: state => state.data,
      unavailabilityDetailErrors: state => state.error,
    }),
  },
  created() {
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DATE_INPUT_VALIDATIONS = DATE_INPUT_VALIDATIONS;
    this.VEHICLES_INDEX = ALGOLIA_INDEXES.vehicles;
    this.uuid = get(this.unavailability, 'uuid') || get(this.initial, 'uuid');
    this.inputs.vehicle_uuid = get(this.unavailability, 'vehicle_uuid') || get(this.initial, 'vehicle_uuid');
  },
  methods: {
    upperFirst,
    async createUnavailability() {
      await this.addUnavailability(this.dataToSend);
      this.afterRequest();
    },
    async editUnavailability() {
      await this.updateUnavailability({
        uuid: this.uuid,
        unavailability: (this.isEntityStarted) ? omit(this.dataToSend, ['start']) : this.dataToSend,

      });
      this.afterRequest();
    },
    afterRequest() {
      if (this.status.LOADED) {
        this.closeModal();
        this.callback(this.unavailabilityDetailData);
      } else if (this.status.ERROR) {
        this.$throwError(this.unavailabilityDetailErrors);
      }
    },
    onSuccessRemoveCallback() {
      this.afterRequest();
      this.closeModal();
    },
    closeRemoveModal() {
      this.isRemoveModalVisible = false;
    },
    ...mapActions(DOMAINS_MODEL.carsharing.availability.unavailability.detail, [
      'addUnavailability',
      'updateUnavailability',
    ]),
  },
};
</script>
<template>
  <div
    class="UnavailabilityFormComponent"
    data-test-id="unavailability-form"
  >
    <GenericModalComponent
      v-if="!isRemoveModalVisible"
      v-bind="$attrs"
      :title="modalTitle"
      data-test-id="modal"
    >
      <template slot="body">
        <MuiValidationWrapper
          class="row"
          @areAllValid="isValid => isFormValid = isValid"
        >
          <div class="col-12 mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.vehicle_uuid"
              :index="VEHICLES_INDEX"
              :filters="`${getOperatorFilter({ index: VEHICLES_INDEX })} AND non_connected:0`"
              :title="result => `${result.license_plate} - ${result.vehicle_brand} ${result.vehicle_model}`"
              :label="upperFirst($t('Common.Business.vehicle')) + '*'"
              :disabled="!!unavailability"
              :placeholder="$t('Common.Actions.select_element', {element: $t('Common.Business.vehicle')})"
              path-value="uuid"
              class="w-100"
              name="vehicle_uuid"
              data-test-id="vehicle-select"
            />
          </div>
          <div class="col-12 col-sm-6 mb-4 mb-sm-0">
            <MuiDatePicker
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date="start"
              :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
              :disabled="isEntityStarted"
              :label="upperFirst($t('Common.Time.start_date'))+ '*'"
              class="w-100"
              data-test-id="start_date-select"
              @update:date="setStart"
            />
          </div>
          <div class="col-12 col-sm-6">
            <MuiDatePicker
              v-validate="{
                isRequired: true,
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :date="end"
              :disabled="isEntityFinished"
              :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
              :label="upperFirst($t('Common.Time.end_date'))+ '*'"
              class="w-100"
              data-test-id="end_date-select"
              @update:date="setEnd"
            />
          </div>
          <div
            v-if="!unavailability"
            class="col-12 mt-4"
          >
            <ui-text-area
              :value="inputs.comment"
              label="Comment"
              placeholder="Reason for creating the unavailability"
              name="comment"
              data-test-id="comment-textarea"
              class="d-block"
              rows="10"
              @changevalue="({ detail }) => inputs.comment = detail"
            />
          </div>
        </MuiValidationWrapper>
      </template>
      <template slot="footer">
        <div class="d-flex flex-fill align-content-center justify-content-between">
          <div class="d-flex justify-content-center">
            <DeleteButton
              v-if="unavailability && !isEntityStarted"
              data-test-id="remove_modal-button"
              @click="isRemoveModalVisible = true"
            />
          </div>

          <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
            <div class="d-flex justify-content-center">
              <CancelButton
                data-test-id="close_modal-button"
                @click="closeModal"
              />
            </div>

            <div class="d-flex justify-content-center">
              <ui-button
                :disabled="!areAllRequiredFieldsFilled"
                :loading="status.LOADING"
                class="wmin-initial"
                data-test-id="edit_create-button"
                @clickbutton="unavailability ? editUnavailability() : createUnavailability()"
              >
                {{ unavailability ? $t('Common.Actions.save') : $t('Common.Actions.create') }}
              </ui-button>
            </div>
          </div>
        </div>
      </template>
    </GenericModalComponent>
    <RemoveUnavailabilityFormComponent
      v-if="isRemoveModalVisible"
      :header="null"
      :unavailability="unavailability"
      :callback="onSuccessRemoveCallback"
      :close-modal="closeRemoveModal"
      data-test-id="remove-unavailability-form"
    />
  </div>
</template>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._b(
      {
        staticClass: "DeleteLongDistanceForm",
        attrs: {
          size: _vm.SIZES.small,
          "is-open": "",
          "data-test-id": "long_distance-delete-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: {
                    text: "Are you sure you want to delete the this Long Distance?",
                    "data-test-id": "delete-body",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c("DeleteButton", {
                  attrs: {
                    loading: _vm.longDistanceDetailStatus.LOADING,
                    "data-test-id": "delete-button",
                  },
                  on: { click: _vm.removeLongDistance },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "GenericModalComponent",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
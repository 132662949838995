var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "VehicleAllocationEventComponent overflow-x-auto",
      attrs: { "data-test-id": "vehicles_allocation-event" },
    },
    [
      _c(
        "div",
        {
          staticClass: "text-ellipsis",
          attrs: { title: _vm.tooltipText, "data-test-id": "tooltip" },
        },
        [
          _c("b", { staticClass: "emobg-font-weight-bold" }, [
            _vm._v(
              " #" +
                _vm._s(_vm.unavailability.reference) +
                " - " +
                _vm._s(
                  _vm.upperFirst(_vm.$t("Common.Business.unavailability"))
                ) +
                " "
            ),
          ]),
          _vm._v(
            " ( " +
              _vm._s(
                _vm
                  .moment(_vm.unavailability.start)
                  .format(_vm.DATE_FORMAT.defaultExtended)
              ) +
              " to " +
              _vm._s(
                _vm
                  .moment(_vm.unavailability.end)
                  .format(_vm.DATE_FORMAT.defaultExtended)
              ) +
              " ) "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
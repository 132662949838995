<script>
import moment from 'moment-timezone'; // Import necessary for `data` hook

import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import each from 'lodash/each';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import includes from 'lodash/includes';
import assign from 'lodash/assign';
import kebabCase from 'lodash/kebabCase';

import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiCard, MuiInputText, MuiSelect } from '@emobg/motion-ui/v1';
import { DATE_FORMAT } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import {
  DayPilot,
  FullscreenButton,
  MobileFiltersWrapperComponent,
  MonthSelectorComponent,
} from '@/components';
import { DATE } from '@/components/DayPilot/constants';
import EventComponent from './components/Event/VehicleAllocationEventComponent';
import VehicleAllocationFormComponent from './VehicleAllocationForm/VehicleAllocationFormComponent';
import AvailabilityModalMixin from '../../mixins/AvailabilityModalMixin';
import AvailabilityMobileFilters from '../AvailabilityMobileFilters/AvailabilityMobileFilters';

export default {
  components: {
    AvailabilityMobileFilters,
    DayPilot,
    FullscreenButton,
    MobileFiltersWrapperComponent,
    MonthSelectorComponent,
    MuiCard,
    MuiInputText,
    MuiSelect,
    VehicleAllocationFormComponent,
  },
  mixins: [AvailabilityModalMixin],
  data() {
    return {
      resources: [],
      events: [],
      citiesToRequest: [],
      filters: {
        date: moment().startOf('month'),
        cities: [],
        search: '',
        selectedView: '0',
      },
      timeoutFilters: null,
      showFiltersMobile: false,
      fullscreen: false,
      loading: true,
    };
  },
  computed: {
    viewTypes() {
      return [
        {
          label: 'Month',
          config: {
            days: this.moment(this.filters.date).endOf('year').diff(this.moment(this.filters.date).startOf('year'), 'days') + 1,
            scale: 'Day',
            timeHeaders: [
              { groupBy: 'Month', format: 'MMM yyyy' },
              { groupBy: 'Day', format: 'dd' },
            ],
          },
        },
        {
          label: '3 Months',
          config: {
            days: this.moment(this.filters.date).endOf('month').add(2, 'months').diff(this.moment(this.filters.date).startOf('month'), 'days') + 1,
            scale: 'Month',
            timeHeaders: [
              { groupBy: 'Year', format: 'yyyy' },
              { groupBy: 'Month', format: 'MMMM' },
            ],
          },
        },
        {
          label: 'Year',
          config: {
            days: this.moment(this.filters.date).endOf('year').diff(this.moment(this.filters.date).startOf('year'), 'days') + 1,
            scale: 'Month',
            timeHeaders: [
              { groupBy: 'Year', format: 'yyyy' },
              { groupBy: 'Month', format: 'MMM' },
            ],
          },
        },
      ];
    },
    filterByOperator() {
      let filters = 'cs_operator_fk:undefined';

      if (!this.activeOperator) {
        return filters;
      }

      filters = `cs_operator_fk:${this.activeOperator.id}`;
      each(this.activeOperator.childrenIds, operatorChildId => {
        filters += ` OR cs_operator_fk:${operatorChildId}`;
      });

      return filters;
    },
    citiesToRequestUUIDs() {
      return map(this.citiesToRequest, item => item.uuid);
    },
    filtersDate() {
      return this.filters.date.format(DATE_FORMAT.date);
    },
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperator: userState => userState.operators.active,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.cities, {
      citiesData: state => state.data,
      citiesStatus: state => state.STATUS,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.availability.vehicleAllocations.list, {
      vehicleAllocationsStatus: state => state.STATUS,
      vehicleAllocationsData: state => state.data,
      vehicleAllocationsErrors: state => state.error,
    }),
  },
  watch: {
    'filters.search': {
      handler() {
        this.handleDpFilters();
      },
      deep: true,
    },
    'filters.cities': {
      handler() {
        this.handleDpFilters();
      },
      deep: true,
    },
    filtersDate(val, old) {
      if (!isEqual(val, old)) {
        this.listVehicleAllocations();
        this.resetFilters();
      }
    },
    citiesToRequestUUIDs(val, old) {
      if (!isEqual(val, old)) {
        this.listVehicleAllocations();
        this.resetFilters();
      }
    },
    citiesData(value) {
      if (isArray(value) && value.length) {
        this.setCitiesToRequest();
      }
    },
    filterByOperator() {
      this.resetStore();
      this.setCitiesToRequest();
    },
  },
  mounted() {
    this.setCitiesToRequest();
    window.addEventListener('keyup', this.escapeListener);
    window.addEventListener('resize', this.resizeListener);
  },
  destroyed() {
    window.removeEventListener('keyup', this.escapeListener);
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    map,
    upperFirst,
    kebabCase,
    citiesSelectedText(number) {
      const element = this.$t('Common.Business.cities_number', { number });

      return this.$t('Common.ActionsFinished.selected_element', { element });
    },
    resizeListener() {
      const dayPilotShowRowHeaderElement = document.querySelector('.scheduler_default_header_icon_show');

      if (window.matchMedia('(max-width: 767px)').matches) {
        this.fullscreen = false;
      } else if (dayPilotShowRowHeaderElement) {
        dayPilotShowRowHeaderElement.click();
      }
    },
    escapeListener(e) {
      if (e.key === 'Escape') {
        this.fullscreen = false;
      }
    },
    resetFilters() {
      this.filters.cities = cloneDeep(this.citiesToRequestUUIDs);
      this.filters.search = '';
    },
    handleDpFilters() {
      if (this.$refs.daypilot && this.$refs.daypilot.dp) {
        if (this.timeoutSearch !== null) {
          clearTimeout(this.timeoutSearch);
        }
        this.timeoutSearch = setTimeout(() => {
          try {
            this.$refs.daypilot.dp.rows.filter(this.filters);
          } catch (e) {
            //
          }
        }, 300);
      }
    },
    onTimeRangeSelecting(element) {
      if (this.moment(element.start, DATE.detail).isBefore(this.moment())) {
        element.allowed = false;
        element.left.enabled = true;
        element.left.html = 'You can not add vehicle allocation before now';
      }
    },
    onTimeRangeSelected(element) {
      this.$refs.daypilot.dp.clearSelection();
      const start = this.moment(element.start, DATE.detail);
      const end = this.moment(element.end, DATE.detail).subtract(1, 'seconds');
      if (!start.isBefore(this.moment())) {
        this.openVehicleAllocationForm(null, {
          start,
          end,
          location_uuid: element.resource,
          city_uuid: this.getCityFromResource(element.resource),
        });
      }
    },
    onEventClick(element) {
      this.openVehicleAllocationForm({
        uuid: element.e.data.id,
        start: this.moment(element.e.data.start, DATE.detail),
        end: this.moment(element.e.data.end, DATE.detail),
        location_uuid: element.e.data.tags.vehicleAllocation.location_uuid,
        vehicle_uuid: element.e.data.tags.vehicleAllocation.vehicle.uuid,
        city_uuid: element.e.data.tags.vehicleAllocation.city_uuid,
      });
    },
    /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["element"] }] */
    onRowFilter(element) {
      const isPlateIncluded = lp => lp.toLowerCase().includes(element.filter.search.toLowerCase());
      if (element.row.level === 0
        && (
          !element.row.data.html.toLowerCase().includes(element.filter.search.toLowerCase())
          && findIndex(element.row.data.tags.license_plates, isPlateIncluded)) === -1
      ) {
        element.visible = false;
      }

      // Cities
      if (this.filters.cities.length > 0) {
        const cityToSearch = includes(this.filters.cities, element.row.data.city_uuid);

        if (!cityToSearch) {
          element.visible = false;
        }
      } else {
        element.visible = false;
      }
    },
    currentView() {
      return (this.$refs.daypilot) ? this.$refs.daypilot.currentViewTypeIndex : 0;
    },
    setViewTypeByIndex(index) {
      this.$refs.daypilot.setCurrentViewTypeIndex(index);
      this.filters.selectedView = `${index}`;
    },
    resourcesBubble({ Bubble, find: findBubble }) {
      return new Bubble({
        animated: false,
        showAfter: 300,
        zIndex: 30,
        onLoad: param => {
          const resource = findBubble(param.source.id);
          assign(param, { html: resource.data.html });
        },
      });
    },
    onModalSuccessCallback() {
      this.closeModal();
      this.listVehicleAllocations();
      this.resetFilters();
    },
    openVehicleAllocationForm(vehicleAllocation, initial = null) {
      this.entityToEdit = vehicleAllocation;
      this.initialData = initial;
      this.isModalVisible = true;
    },
    getCityFromResource(uuid) {
      if (this.vehicleAllocationsData) {
        const location = find(this.vehicleAllocationsData, item => item.uuid === uuid);

        return (location) ? location.city_uuid : null;
      }

      return null;
    },
    setCitiesToRequest() {
      this.citiesToRequest = this.citiesData;
    },
    resourceTransformer(location) {
      return {
        id: location.uuid,
        uuid: location.uuid,
        city_uuid: location.city_uuid,
        html: `${location.name}`,
        tags: {
          license_plates: [],
        },
      };
    },
    eventTransformer(vehicleAllocation) {
      const Component = Vue.extend(EventComponent);

      return {
        id: vehicleAllocation.uuid,
        uuid: vehicleAllocation.uuid,
        resource: vehicleAllocation.location_uuid,
        start: vehicleAllocation.start,
        end: vehicleAllocation.end,
        html: new Component({
          propsData: {
            vehicleAllocation,
          },
        }).$mount().$el.outerHTML,
        tags: {
          vehicleAllocation,
        },
      };
    },
    getResourcesAndEvents() {
      return new Promise(resolve => {
        const r = {
          resources: [],
          events: [],
        };

        if (isArray(this.vehicleAllocationsData) && this.vehicleAllocationsData.length > 0) {
          this.vehicleAllocationsData.forEach(location => {
            const l = this.resourceTransformer(location);

            location.vehicle_location.forEach(vehicleAllocation => {
              if (l.tags.license_plates.indexOf(vehicleAllocation.vehicle.license_plate) === -1) {
                l.tags.license_plates.push(vehicleAllocation.vehicle.license_plate);
              }

              r.events.push(this.eventTransformer(assign(vehicleAllocation, { city_uuid: l.city_uuid })));
            });

            r.resources.push(l);
          });
        }

        resolve(r);
      });
    },
    async listVehicleAllocations() {
      await this.getVehicleLocations({
        city_uuids: this.citiesToRequestUUIDs,
        start: this.filtersDate,
        end: this.moment(this.filters.date).add(1, 'years').format('YYYY-MM-DD'),
      });

      this.loading = false;
      if (this.vehicleAllocationsStatus.LOADED) {
        this.getResourcesAndEvents()
          .then(response => {
            this.resources = response.resources;
            this.events = response.events;
          });
      } else if (this.vehicleAllocationsStatus.ERROR) {
        this.$throwError(this.vehicleAllocationsErrors);
      }
    },
    ...mapActions(DOMAINS_MODEL.carsharing.availability.vehicleAllocations.list, [
      'getVehicleLocations',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.availability.vehicleAllocations.list, [
      'resetStore',
    ]),
  },
};
</script>

<template>
  <div
    class="VehicleAllocationView full-height d-flex flex-column flex-fill"
    data-test-id="vehicle_allocation-view"
  >
    <!-- MOBILE SUBHEADER -->
    <div
      class="VehicleAllocationView__mobileSubHeader row no-gutters px-2 d-flex d-md-none"
      data-test-id="mobile_sub_header"
    >
      <div class="col col-10">
        <MuiInputText
          v-model="filters.search"
          :placeholder="upperFirst($t('Common.Actions.search'))"
          :icon="ICONS.search"
          class="w-100 mb-2"
          name="search"
          icon-to-right
          data-test-id="search_filters-input"
        />
      </div>
      <div class="col col-2 mb-3 d-flex align-items-center justify-content-center">
        <div
          class="font-xl d-inline-block icons-filter"
          data-test-id="show_filters-button"
          @click="showFiltersMobile = true"
        />
      </div>
    </div>
    <!-- END MOBILE SUBHEADER -->

    <!-- MAIN CARD -->
    <MuiCard
      :class="[
        'd-flex flex-column flex-fill VehicleAllocationView__main-card',
        {
          'Availability__main-card--fullscreen': fullscreen,
          'position-relative': !fullscreen,
        }
      ]"
      data-test-id="main-card"
    >
      <!-- LOADER -->
      <ui-loader
        v-if="vehicleAllocationsStatus.LOADING || loading"
        absolute
        data-test-id="loader"
      />
      <!-- END LOADER -->
      <div class="d-flex flex-column flex-fill">
        <!-- FILTERS DESKTOP -->
        <div class="row no-gutters align-items-center d-none d-md-flex">
          <div class="col col-md-4 col-lg-2 pr-3 mb-2">
            <MuiInputText
              v-model="filters.search"
              :placeholder="upperFirst($t('Common.Actions.search'))"
              :icon="ICONS.search"
              class="w-100 mb-2"
              name="search"
              icon-to-right
              data-test-id="search_filters-input"
            />
          </div>
          <div class="col col-md-4 col-lg-2 pr-3 mb-2">
            <MuiSelect
              v-model="filters.cities"
              :placeholder="upperFirst($t('Common.Business.cities'))"
              :options="citiesToRequest"
              name="cities"
              multiple
              class="w-100 mb-2"
              option-label="name"
              option-value="uuid"
              data-test-id="cities-select"
            />
          </div>
          <div class="col col-md-4 col-lg-2 pr-3 mb-2">
            <MonthSelectorComponent
              v-model="filters.date"
              data-test-id="date-select"
            />
          </div>
          <div class="col col-md-4 col-lg pr-3 mb-2">
            <div class="d-flex justify-content-start mb-2">
              <ui-button-segments
                :value="currentView()"
                :options.prop="map(viewTypes, ({ label }, index) => ({ label, value: index }))"
                :default-color="GRAYSCALE.inkLight"
                class="Ui-ButtonSegments--minw-initial"
                data-test-id="set_days-segmented_button"
                @clickbuttonsegment="event => setViewTypeByIndex(event.detail)"
              />
            </div>
          </div>
          <div class="col col-md-8 col-lg-auto mb-2 d-flex justify-content-end">
            <FullscreenButton
              :fullscreen="fullscreen"
              data-test-id="fullscreen-button"
              @click="fullscreen = !fullscreen"
            />
            <ui-button
              class="ml-2 wmin-initial mb-2"
              data-test-id="open_vehicle_allocation_form-button"
              @clickbutton="openVehicleAllocationForm()"
            >
              Create allocation
            </ui-button>
          </div>
        </div>
        <!-- END FILTERS DESKTOP -->

        <!-- FILTERS MOBILE -->
        <MobileFiltersWrapperComponent
          v-model="showFiltersMobile"
          data-test-id="mobile-filters"
        >
          <template slot="filters">
            <AvailabilityMobileFilters
              v-model="filters"
              :cities-to-request="citiesToRequest"
              :set-view-type="setViewTypeByIndex"
              data-test-id="availability"
            />
          </template>
        </MobileFiltersWrapperComponent>
        <!-- END FILTERS MOBILE -->

        <!-- SCHEDULER -->
        <DayPilot
          ref="daypilot"
          :date="filters.date"
          :events="events"
          :resources="resources"
          :config="{
            rowHeaderHideIconEnabled: true,
          }"
          :views="viewTypes"
          :resources-bubble="resourcesBubble"
          :on-row-filter="onRowFilter"
          :on-event-clicked="onEventClick"
          :on-time-range-selected="onTimeRangeSelected"
          :on-time-range-selecting="onTimeRangeSelecting"
          class="d-flex flex-column full-height flex-fill"
          script-url="/js/daypilot-all.min.js"
          data-test-id="daypilot"
        />
        <!-- END SCHEDULER -->
      </div>
    </MuiCard>
    <VehicleAllocationFormComponent
      v-if="isModalVisible"
      :vehicle-allocation="entityToEdit"
      :initial="initialData"
      :callback="onModalSuccessCallback"
      :close-modal="closeModal"
      data-test-id="vehicle_allocation-form"
    />
  </div>
</template>

import { GenericModalComponent } from '@/components';

export default {
  components: {
    GenericModalComponent,
  },
  data() {
    return {
      isModalVisible: false,
      entityToEdit: null,
      initialData: null,
    };
  },
  methods: {
    closeModal() {
      this.entityToEdit = null;
      this.initialData = null;
      this.isModalVisible = false;
    },
  },
};

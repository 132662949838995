<script>
import upperFirst from 'lodash/upperFirst';
import kebabCase from 'lodash/kebabCase';
import moment from 'moment-timezone';
import { MonthSelectorComponent } from '@/components';

export default {
  components: {
    MonthSelectorComponent,
  },
  model: {
    prop: 'filters',
    event: 'change',
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
    setViewType: {
      type: Function,
      default: () => {},
    },
    citiesToRequest: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    viewTypes() {
      return [
        {
          label: 'Month',
          config: {
            days: moment(this.filters.date).endOf('year').diff(moment(this.filters.date).startOf('year'), 'days') + 1,
            scale: 'Day',
            timeHeaders: [
              { groupBy: 'Month', format: 'MMM yyyy' },
              { groupBy: 'Day', format: 'dd' },
            ],
          },
        },
        {
          label: '3 Months',
          config: {
            days: moment(this.filters.date).endOf('month').add(2, 'months').diff(moment(this.filters.date).startOf('month'), 'days'),
            scale: 'Month',
            timeHeaders: [
              { groupBy: 'Year', format: 'yyyy' },
              { groupBy: 'Month', format: 'MMMM' },
            ],
          },
        },
        {
          label: 'Year',
          config: {
            days: moment(this.filters.date).endOf('year').diff(moment(this.filters.date).startOf('year'), 'days'),
            scale: 'Month',
            timeHeaders: [
              { groupBy: 'Year', format: 'yyyy' },
              { groupBy: 'Month', format: 'MMM' },
            ],
          },
        },
      ];
    },
  },
  watch: {
    filters: {
      handler() {
        this.$emit('change', this.filters);
      },
      deep: true,
    },
  },
  methods: {
    kebabCase,
    upperFirst,
  },
};
</script>
<template>
  <div
    class="AvailabilityMobileFilters row"
    data-test-id="availability_mobile-filters"
  >
    <div class="col-12 mb-3">
      <ui-select-multiple
        v-if="filters.cities"
        :values.prop="filters.cities"
        :placeholder="upperFirst($t('Common.Business.cities'))"
        :options.prop="citiesToRequest && citiesToRequest.map(({ uuid, name }) => ({ value: uuid, label: name }))"
        name="cities"
        class="w-100 mb-2"
        data-test-id="cities-select"
        @selectoptions="({ detail }) => filters.cities = detail"
      />
    </div>
    <div class="col-12">
      <MonthSelectorComponent
        v-model="filters.date"
        data-test-id="month-select"
      />
    </div>
    <div class="col-12">
      <div
        v-for="(viewType, index) in viewTypes"
        :key="viewType.label"
      >
        <ui-radio
          :value="filters.selectedView"
          :caption="viewType.label"
          :option="`${index}`"
          class="mb-3 mt-2 w-100"
          name="viewType"
          :data-test-id="`type-${kebabCase(viewType.label)}-button`"
          @changevalue="() => {
            filters.selectedView = index;
            setViewType(index);
          }"
        />
      </div>
    </div>
  </div>
</template>

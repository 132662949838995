<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteButton,
    GenericModalComponent,
    DeleteModalBodyComponent,
  },
  props: {
    unavailability: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.availability.unavailability.detail, {
      isLoading: state => state.STATUS.LOADING,
    }),
  },
  methods: {
    async removeUnavailability() {
      await this.deleteUnavailability(this.unavailability.uuid);
      this.closeModal();
      this.callback();
    },
    ...mapActions(DOMAINS_MODEL.carsharing.availability.unavailability.detail, [
      'deleteUnavailability',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    class="DeleteUnavailabilityForm"
    v-bind="$attrs"
    :size="SIZES.small"
    data-test-id="unavailability_form-delete-modal"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        :text="$t('Common.Questions.delete_element', { element: $t('Common.Business.unavailability') })"
        data-test-id="delete-modal"
      />
    </template>
    <template slot="footer">
      <CancelButton
        data-test-id="cancel-button"
        @click="closeModal"
      />
      <DeleteButton
        :loading="isLoading"
        data-test-id="delete-button"
        @click="removeUnavailability"
      />
    </template>
  </GenericModalComponent>
</template>

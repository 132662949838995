var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "VehicleAllocationEventComponent text-ellipsis",
      attrs: { "data-test-id": "vehicle_allocation-event" },
    },
    [
      _c(
        "div",
        {
          staticClass: "text-ellipsis",
          attrs: { title: _vm.tooltipText, "data-test-id": "tooltip" },
        },
        [
          _c("b", { staticClass: "emobg-font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.vehicleAllocation.vehicle.license_plate) +
                " - " +
                _vm._s(_vm.vehicleAllocation.vehicle.brand) +
                " " +
                _vm._s(_vm.vehicleAllocation.vehicle.model) +
                " "
            ),
          ]),
          _vm._v(
            " ( " +
              _vm._s(
                _vm
                  .moment(_vm.vehicleAllocation.start)
                  .format(_vm.DATE_FORMAT.defaultExtended)
              ) +
              " to " +
              _vm._s(
                _vm
                  .moment(_vm.vehicleAllocation.end)
                  .format(_vm.DATE_FORMAT.defaultExtended)
              ) +
              " ) "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
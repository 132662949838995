<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteButton,
    GenericModalComponent,
    DeleteModalBodyComponent,
  },
  props: {
    longDistance: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.availability.longDistance.detail, {
      longDistanceDetailStatus: state => state.STATUS,
    }),
  },
  methods: {
    async removeLongDistance() {
      await this.deleteLongDistance(this.longDistance.uuid);
      this.$emit('closeModal');
      this.callback();
    },
    ...mapActions(DOMAINS_MODEL.carsharing.availability.longDistance.detail, [
      'deleteLongDistance',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    v-bind="$attrs"
    :size="SIZES.small"
    class="DeleteLongDistanceForm"
    is-open
    data-test-id="long_distance-delete-modal"
  >
    <template #body>
      <DeleteModalBodyComponent
        text="Are you sure you want to delete the this Long Distance?"
        data-test-id="delete-body"
      />
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :loading="longDistanceDetailStatus.LOADING"
        data-test-id="delete-button"
        @click="removeLongDistance"
      />
    </template>
  </GenericModalComponent>
</template>

<script>
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { DATE_FORMAT } from '@emobg/web-utils';

export default {
  name: 'UnavailabilityEventComponent',
  props: {
    unavailability: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      DATE_FORMAT,
    };
  },
  computed: {
    tooltipText() {
      const start = this.moment(get(this.unavailability, 'start')).format(DATE_FORMAT.defaultExtended);
      const end = this.moment(get(this.unavailability, 'end')).format(DATE_FORMAT.defaultExtended);

      return `#${this.unavailability.reference} - ${upperFirst(this.$t('Common.Business.unavailability'))} (${start} to ${end})`;
    },
  },
  methods: {
    upperFirst,
  },
};
</script>
<template>
  <div
    class="VehicleAllocationEventComponent overflow-x-auto"
    data-test-id="vehicles_allocation-event"
  >
    <div
      :title="tooltipText"
      class="text-ellipsis"
      data-test-id="tooltip"
    >
      <b class="emobg-font-weight-bold">
        #{{ unavailability.reference }} - {{ upperFirst($t('Common.Business.unavailability')) }}
      </b>
      (
      {{ moment(unavailability.start).format(DATE_FORMAT.defaultExtended) }}
      to
      {{ moment(unavailability.end).format(DATE_FORMAT.defaultExtended) }}
      )
    </div>
  </div>
</template>
